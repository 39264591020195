<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
      @click="resetValues()"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUserAccountInfo.first_name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUserAccountInfo.first_name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
          <div class="d-flex flex-column pt-4">
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
            <button @click="collapse1=!collapse1;collapse2=false;" v-b-toggle.collapse-1 class="btn btn-light-primary btn-bold mt-8">
              Change Profile
            </button>

            <button @click="collapse1=false;collapse2=!collapse2" class="btn btn-light-primary btn-bold mt-8">
              Change Password
            </button>
          </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <div>
          <b-collapse v-model="collapse1" class="mt-2">
            <b-card>
              <v-row>
                <v-col cols="12">
                  <label> First Name:</label>
                  <b-form-input v-model="formData.first_name" placeholder="First Name" class="modal-input"></b-form-input>
                  <div v-if="errors && errors.first_name && errors.first_name.length >0">
                    <span class="text-danger" v-for="(error, i) in errors.first_name">{{ error }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label> Last Name:</label>
                  <b-form-input v-model="formData.last_name" placeholder="Last Name" class="modal-input"></b-form-input>
                  <div v-if="errors && errors.last_name && errors.last_name.length >0">
                    <span class="text-danger" v-for="(error, i) in errors.last_name">{{ error }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label> Email:</label>
                  <b-form-input type="email" v-model="formData.email" placeholder="Email" class="modal-input"></b-form-input>
                  <div v-if="errors && errors.email && errors.email.length >0">
                    <span class="text-danger" v-for="(error, i) in errors.email">{{ error }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" offset="1" style="text-align: right">
                  <span class="text-danger" v-if="formDataSaved">Saved!</span> <b-button class="m-4" variant="primary" pill :disabled="!(formData.last_name && formData.first_name && formData.email)" @click="updateProfile">Save</b-button>
                </v-col>
              </v-row>
            </b-card>
          </b-collapse>

           <b-collapse v-model="collapse2" class="mt-2">
            <b-card>
              <v-row>
                <v-col cols="12">
                  <label> Old Password:</label>
                  <b-form-input type="password" v-model="formData2.old_password" placeholder="Old Password" class="modal-input"></b-form-input>
                  <div v-if="errors && errors.old_password && errors.old_password.length >0">
                    <span class="text-danger" v-for="(error, i) in errors.old_password">{{ error }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>New Password:</label>
                  <b-form-input type="password" v-model="formData2.new_password" placeholder="New Password" class="modal-input"></b-form-input>
                  <div v-if="errors && errors.new_password && errors.new_password.length >0">
                    <span class="text-danger" v-for="(error, i) in errors.new_password">{{ error }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label> Password Confirm:</label>
                  <b-form-input  type="password" v-model="formData2.new_password_confirm" placeholder="Password Confirm" class="modal-input"></b-form-input>
                  <div v-if="errors && errors.new_password_confirm && errors.new_password_confirm.length >0">
                    <span class="text-danger" v-for="(error, i) in errors.new_password_confirm">{{ error }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" offset="1" style="text-align: right">
                  <span class="text-danger" v-if="formDataSaved2">Updated!</span> <b-button class="m-4" variant="primary" pill :disabled="!(formData2.old_password && formData2.new_password && formData2.new_password_confirm && formData2.new_password_confirm == formData2.new_password)" @click="updatePassword">Save</b-button>
                </v-col>
              </v-row>
            </b-card>
          </b-collapse>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {mapGetters, mapState} from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import {UPDATE_PROFILE, UPDATE_PASSWORD} from "../../../../core/services/store/profile.module";
export default {
  name: "KTQuickUser",
  data() {
    return {
      formDataSaved: false,
      collapse1: false,
      collapse2: false,
      formDataSaved2: false,
      formData :{
         first_name : '',
         last_name : '',
         email : ''
      },
      formData2 :{
         old_password : '',
         new_password : '',
         new_password_confirm : ''
      }
    };
  },
  mounted() {
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    resetValues(){
      this.formDataSaved = false;
      this.formDataSaved2 = false;
      this.collapse1= false;
      this.collapse2 = false;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    updateProfile() {
      this.$store
        .dispatch(UPDATE_PROFILE, this.formData)
        .then(()=>{
          this.formDataSaved=true;
        });
    },
    updatePassword() {
      this.$store
        .dispatch(UPDATE_PASSWORD, this.formData2)
        .then(()=>{
          this.formDataSaved2=true;
        });
    },
  },
  created (){
  },
  watch: {
    currentUserAccountInfo(new_value, old_value) {
      this.formData = this.currentUserAccountInfo;
    }
  },

  computed: {
    ...mapState({
      errors: state => state.profile.errors
    }),
    ...mapGetters(["currentUserAccountInfo"]),
  },
};
</script>
